<template>
  <div>
    <TagInput
      :disabled="disabled"
      label="Copy Names Mode"
      :value="selectedItems"
      :items="copyNameModeOptions"
      @input="handleInput"
    />
  </div>
</template>

<script>
import TagInput from "@/components/common/TagInput.vue";
import { bitEnumToArray } from "@/modules/helpers";

export default {
  components: { TagInput },
  props: ["value", "disabled"],
  data() {
    return {
      copyNameModeOptions: [
        {
          displayName: "Lab",
          id: 1
        },
        {
          displayName: "Secondary Provider",
          id: 2
        },
        {
          displayName: "Copy Names Type Code",
          id: 4
        }
      ],
      selectedItems: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.selectedItems = bitEnumToArray(nv);
      }
    }
  },
  methods: {
    handleInput(value) {
      const sum = value.reduce((partialSum, a) => partialSum + a, 0);
      this.$emit("input", sum || null);
    }
  }
};
</script>

<style lang="scss" scoped></style>
